













































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import useCompanyMode from "@/use/companyMode";
import useUser from "@/use/user";

export default defineComponent({
  setup(props, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const {
      setCompany,
      removeCompany,
      isCompanyModeEnabled,
      getCompanyId,
    } = useCompanyMode({ root });

    const state = reactive({
      loading: false,
      user: computed(() => root.$store.getters["user/getData"]),
      companies: computed(() => root.$store.getters["user/getCompanies"]),
    });

    onMounted(() => {
      root.$store.dispatch("user/updateCompany");
    });

    return {
      hasAccessTo,
      hasManyCompanies,
      setCompany,
      removeCompany,
      isCompanyModeEnabled,
      getCompanyId,
      state,
    };
  },
});
